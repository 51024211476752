<template>
  <v-row no-gutters>
    <v-col>
      <div class="app__filter">
        <!--поле выбора-->
        <div
          class="app__filter-block-input"
          style="border: 1px solid #d7d7e0; border-radius: 10px"
          @click.stop="switchShow"
        >
          <!--операции-->
          <div style="width: 100%">
            <div style="display: inline-flex">
              <div style="margin-left: 10px; margin-right: 10px; color: black">
                <v-row class="d-flex align-center">
                  <v-col style="font-size: 1.5em; padding-right: 0" cols="auto">
                    Фильтр:
                  </v-col>
                </v-row>
              </div>
            </div>
            <div
              v-for="(item, i) in fastFilter.pbr"
              :key="`operation${i}`"
              class="app__filter-chip"
            >
              <div class="app__filter-chip-content">
                {{ item.label }}
                <v-icon
                  class="app__filter-chip-icon-append"
                  @click.stop="clearItemFastFilter('pbr', item)"
                >
                  $iconify_jam-close
                </v-icon>
              </div>
            </div>

            <!--валюты-->
            <div
              v-for="(item, i) in fastFilter.bu"
              :key="`unit${i}`"
              class="app__filter-chip"
            >
              <div class="app__filter-chip-content">
                <v-icon v-if="item.is_main">
                  mdi-star
                </v-icon>
                {{ item.label }}
                <v-icon
                  class="app__filter-chip-icon-append"
                  @click.stop="clearItemFastFilter('bu', item)"
                >
                  $iconify_jam-close
                </v-icon>
              </div>
            </div>

            <!--операторы-->
            <div
              v-for="(item, i) in fastFilter.operator"
              :key="`operator${i}`"
              class="app__filter-chip"
            >
              <div class="app__filter-chip-content">
                {{ item.label }}
                <v-icon
                  class="app__filter-chip-icon-append"
                  @click.stop="clearItemFastFilter('operator', item)"
                >
                  $iconify_jam-close
                </v-icon>
              </div>
            </div>

            <!--клиенты-->
            <div
              v-for="(item, i) in fastFilter.client"
              :key="`client${i}`"
              class="app__filter-chip"
            >
              <div class="app__filter-chip-content">
                {{ item.label }}
                <v-icon
                  class="app__filter-chip-icon-append"
                  @click.stop="clearItemFastFilter('client', item)"
                >
                  $iconify_jam-close
                </v-icon>
              </div>
            </div>

            <!--поле ввода-->
            <!--chip быстрый поиск-->
            <div v-if="fastFilter.query" class="app__filter-chip">
              <div class="app__filter-chip-content">
                {{ fastFilter.query }}
                <v-icon
                  class="app__filter-chip-icon-append"
                  @click.stop="clearFilterQuery()"
                >
                  $iconify_jam-close
                </v-icon>
              </div>
            </div>
            <!--поле ввода-->
            <v-menu
              v-model="show"
              :close-on-content-click="false"             
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="app__filter-plus">
                  <v-btn
                    style="width: 30px; height: 30px"
                    fab
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </div>
              </template>
              <v-card>
                <v-card-text>
                  <v-row>
                      <v-col>
                        <div class="app__filter-content-header">Операции</div>
                        <div
                          v-for="(item, i) in operations"
                          :key="i"
                          :class="getFilterClass('pbr', item)"
                          @click="setFilter('pbr', item)"
                        >
                          {{ item.title }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div class="app__filter-content-header">Валюта</div>
                        <div
                          v-for="(item, i) in units"
                          :key="i"
                          :class="getFilterClass('bu', item)"
                          @click="setFilter('bu', item)"
                        >
                          <v-icon v-if="item.is_main">
                            mdi-star
                          </v-icon>
                          {{ item.name }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div class="app__filter-content-header">Операторы</div>
                        <div>
                          <v-autocomplete
                            v-model="filter.operator"
                            :items="operators"
                            placeholder="Выберите оператора"
                            item-text="label"
                            item-value="id"
                            outlined
                            multiple
                            chips
                            deletable-chips
                          />
                        </div>
                      </v-col>
                      <v-col>
                        <div class="app__filter-content-header">Клиенты</div>
                        <div>
                          <account-select
                            v-model="filter.client"
                            :program-id="program.id"
                            multiple
                            chips
                            outlined
                            deletable-chips
                          />
                        </div>
                      </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row><v-col>
                    <v-row><v-col><v-divider/></v-col> </v-row>
                    <v-row justify="space-around">
                      <v-col cols="auto">
                        <v-btn @click="clearFastFilter()" color="error">
                          <v-icon left> $iconify_feather-trash </v-icon>
                          Очистить
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn color="primary" @click="apply()">
                          Применить
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col></v-row>                    
                   
                </v-card-actions>
              </v-card>
             
            </v-menu>
          </div>

          <v-icon
            v-show="!emptyFastFilter"
            class="app__filter-block-input-icon-append"
            @click.stop="clearFastFilter"
          >
            $iconify_chrome-close
          </v-icon>
        </div>

        <!--окно фильтра-->
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AccountSelect from "@/components/ModelSelect/AccountSelect";

export default {
  components: {
    AccountSelect,
  },
  data() {
    return {
      filter: null,
      fastFilter: {},
      show: false,
      loading: false,
    };
  },
  computed: {
    accounts() {
      return this.$store.getters["account/account/accountShortList"];
    },
    program() {
      return this.$store.getters["company/program/program"];
    },
    filterStore() {
      return this.$store.getters["widget/filter/filter"];
    },
    filterDefault() {
      return this.$store.getters["widget/filter/filterDefault"];
    },
    operators() {
      return this.$store.getters["widget/operators/operators"];
    },
    operations() {
      return this.$store.getters[
        "company/bonus_resources/activeBonusResourcesShort"
      ];
    },
    units() {
      return this.$store.getters["company/bonus_units/bonusUnits"];
    },
    emptyFastFilter() {
      if (
        this.fastFilter.query ||
        this.fastFilter.pbr.length ||
        this.fastFilter.bu.length ||
        this.fastFilter.operator.length ||
        this.fastFilter.client.length
      )
        return false;
      return true;
    },
  },
  watch: {
    show(v) {
      if (v) {
        this.filter = JSON.parse(JSON.stringify(this.filterStore));
      } else {
        this.filter = Object.assign({}, this.filterDefault);
      }
    },
    program(v) {
      // обнуление при смене программы
      if (v) {
        this.filter = JSON.parse(JSON.stringify(this.filterStore));
        this.fastFilter = JSON.parse(JSON.stringify(this.filterDefault));
        this.setFastFilter(this.filter);
      }
    },
    operators(v) {
      if (v) {
        this.setFastFilter(this.filter);
      }
    },
    operations(v) {
      if (v) {
        this.setFastFilter(this.filter);
      }
    },
    units(v) {
      if (v) {
        this.setFastFilter(this.filter);
      }
    },
  },
  created() {
    this.filter = JSON.parse(JSON.stringify(this.filterStore));
    this.fastFilter = JSON.parse(JSON.stringify(this.filterDefault));
    this.setFastFilter(this.filter);
  },
  methods: {
    async switchShow() {
      this.show = !this.show;
      await this.$nextTick();
      this.$refs.search.focus();
    },
    getFilterClass(field, item) {
      if (this.filter && this.filter[field].includes(item.id))
        return "app__filter-content-chip app__filter-content-chip-active";
      return "app__filter-content-chip";
    },
    setFilter(field, item) {
      const index = this.filter[field].indexOf(item.id);
      if (index === -1) {
        this.filter[field].push(item.id);
      } else {
        this.filter[field].splice(index, 1);
      }
    },
    setFastFilter(filter) {
      if (filter && filter.query)
        this.fastFilter.query = `Быстрый поиск: ${filter.query}`;
      filter.pbr.forEach((item) => {
        const operation = this.operations.find(
          (objItem) => objItem.id === item
        );
        if (operation) {
          const obj = { id: item, label: `Операция: ${operation.title}` };
          if (!this.fastFilter.pbr.find((objItem) => objItem.id === item))
            this.fastFilter.pbr.push(obj);
        }
      });
      filter.bu.forEach((item) => {
        const unit = this.units.find((objItem) => objItem.id === item);
        if (unit) {
          const obj = { id: item, is_main: unit.is_main, label: `Валюта: ${unit.name}` };
          if (!this.fastFilter.bu.find((objItem) => objItem.id === item))
            this.fastFilter.bu.push(obj);
        }
      });
      filter.operator.forEach((item) => {
        const operator = this.operators.find((objItem) => objItem.id === item);
        if (operator) {
          const obj = { id: item, label: `Оператор: ${operator.label}` };
          if (!this.fastFilter.operator.find((objItem) => objItem.id === item))
            this.fastFilter.operator.push(obj);
        }
      });
      filter.client.forEach((item) => {
        const client = this.accounts.find((objItem) => objItem.id === item);
        if (client) {
          const obj = { id: item, label: `Клиент: ${client.client_name}` };
          if (!this.fastFilter.client.find((objItem) => objItem.id === item))
            this.fastFilter.client.push(obj);
        }
      });
    },
    clearItemFastFilter(field, item) {
      const i = this.fastFilter[field].findIndex(
        (objItem) => objItem.id === item.id
      );
      if (i !== -1) this.fastFilter[field].splice(i, 1);

      const filter = JSON.parse(JSON.stringify(this.filterStore));
      const j = filter[field].findIndex((elem) => elem === item.id);
      if (j !== -1) filter[field].splice(j, 1);

      this.$store.commit(
        "widget/filter/filter",
        JSON.parse(JSON.stringify(filter))
      );
    },
    clearFastFilter() {
      this.filter = JSON.parse(JSON.stringify(this.filterDefault));
      this.fastFilter = JSON.parse(JSON.stringify(this.filterDefault));
      this.$store.commit(
        "widget/filter/filter",
        JSON.parse(JSON.stringify(this.filter))
      );
      this.show = false;
    },
    clearFilterQuery() {
      this.fastFilter.query = null;
      const filter = JSON.parse(JSON.stringify(this.filterStore));
      filter.query = null;
      this.$store.commit(
        "widget/filter/filter",
        JSON.parse(JSON.stringify(filter))
      );
    },
    close() {
      this.show = false;
    },
    apply() {
      this.$store.commit("widget/filter/filter", this.filter);
      this.fastFilter = JSON.parse(JSON.stringify(this.filterDefault));
      this.setFastFilter(this.filter);
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .app__filter-content-header {
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #2a2a34;
      }

.app__filter-content-chip {
        display: inline-block;
        margin: 8px 8px 0 0;
        padding: 10px 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        color: #9191a1;
        background: #f2f2f7;
        border-radius: 8px;
        cursor: pointer;
      }

      .app__filter-content-chip-active {
        background: #ebf1ff;
        color: #4776e6;
      }

.app__filter-block-input {
  display: flex;
  align-items: center;
  min-height: 45px;
  color: #9191a1;
  border-bottom: 1px solid #d7d7e0;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .app__filter-block-input-icon-prepend {
    margin: 6px 0 6px 12px;
  }

  .app__filter-block-input-icon-append {
    margin: 6px 12px 6px 0;
  }

  .app__filter-block-input-field {
    outline: none !important;
    margin: 13px 0;
    padding: 0 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
  }
}

.app__filter-block-icon-close {
  color: #b5b5c4 !important;
  cursor: pointer;
}

.app__filter-block-icon-check {
  width: 30px !important;
  height: 28px !important;
  position: relative;
  top: -1px;
  color: #4776e6 !important;
  cursor: pointer;
}

.app__filter-plus {
  display: inline-table;
  align-items: center;
  max-width: 100%;
  padding: 0 6px 0 12px;
}

.app__filter-chip {
  display: inline-flex;
  align-items: center;
  background-color: #ebf1ff;
  border-radius: 6px;
  max-width: 100%;
  margin: 3px;
  padding: 0 6px 0 12px;
  height: 37px;
  white-space: nowrap;

  .app__filter-chip-content {
    align-items: center;
    max-width: 100%;
    color: #4776e6;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
  }

  .app__filter-chip-icon-append {
    margin: 0 0 0 6px;
    color: #4776e6;
  }
}
</style>
