<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        style="width: 1024px"
      >
        <v-data-table
          :headers="tableHeaders"
          :items="tableData"
          :options.sync="tableSettings"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="uuid"
          :show-expand="true"
          class="plus-table"
          hide-default-footer
          :server-items-length="totalCount"
          @update:sort-by="fetchData()"
          @update:sort-desc="fetchData()"
        >
          <template v-slot:[`expanded-item`]="{ headers, item }">
            <td :colspan="headers.length">
              Комментарий: {{item.comment||'-'}}
            </td>
          </template>

          <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
            <v-icon
              color="neutral-500"
              style="width: 16px;"
              @click="expand(!isExpanded)"
            >
              $iconify_ion-chatbox-outline
            </v-icon>
          </template>

          <template v-slot:[`item.title`]="{ item }">
            <div class="cell-text">
              {{ item.title }}
            </div>
            <div
              class="cell-hint"
            >
              ID {{ item.bsid }}
            </div>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <div class="cell-text">
              {{ getDate(item.created_at) }}
            </div>
            <div
              class="cell-hint"
            >
              {{ getTime(item.created_at) }}
            </div>
          </template>

          <template v-slot:[`item.client`]="{ item }">
            <div style="display: flex;">
              <img
                class="table-cell_avatar"
                style="position: relative; top: -5px; cursor: pointer;"
                :src="`${$config.app.fileStorage}${item.client_avatar}`"
                @click.stop="userSidePanel(item)"
              >
              <div>
                <div
                  class="cell-text-bold"
                  style="cursor: pointer;"
                  @click.stop="userSidePanel(item)"
                >
                  {{ item.client_name || $t('no_name') }}
                  <!-- {{ getFIO({id: item.client_id, name: item.client}) }} -->
                </div>
                <div
                  class="cell-hint"
                >
                  {{ getLastActivity(item.last_activity) }}
                </div>
              </div>
            </div>
          </template>

          <template v-slot:[`item.unit`]="{ item }">
            <div class="cell-text">
              <v-icon v-if="isUnitMain(item.unit_id)">mdi-star</v-icon>
              {{ item.unit }}
            </div>
          </template>

          <template v-slot:[`item.value`]="{ item }">
            <div
              class="cell-text-bold"
              style="text-align: right;"
              v-html="getValue(item.value)"
            />
          </template>

          <template v-slot:[`item.operator`]="{ item }">
            <div style="display: flex; align-items: center">
              <img
                class="table-cell_avatar"
                :style="`background-color: ${program.bgcolor1};`"
                :src="(item.operator_avatar === 'system' || item.operator_avatar === 'external')
                  ? program.logo
                  : `${$config.app.fileStorage}${item.operator_avatar}`"
              >
              <div class="cell-text">
                {{ item.operator }}
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row
      align="center"
      class="pagination"
    >
      <v-col>
        <div class="table-pagination-block">
          <div
            style="margin-right: 20px;"
          >
            Всего {{ totalCount }} {{ getWord(totalCount, wordOperations) }} на {{ pagesCount }}
            {{ getWord(pagesCount, wordPages) }}
          </div>

          <select-page-limit
            min-width="200px"
            :items="paginationOptions"
            :model.sync="tableSettings.itemsPerPage"
            item-value="value"
            item-label="text"
          />

          <div class="app__spacer" />

          <div class="text-center">
            <v-pagination
              v-model="tableSettings.page"
              next-icon="fas fa-chevron-right"
              prev-icon="fas fa-chevron-left"
              :length="pagesCount"
              :total-visible="7"
              circle
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <side-panel-edit-client
      v-model="sidePanelStatus.active"
      :mode.sync="sidePanelStatus.mode"
      :data="sidePanelStatus.data"
    />
  </div>
</template>

<script>
  import SelectPageLimit from '@/components/dialogs/SelectPageLimit'
  import FormatNumber from '@/mixins/formatNumber'
  import Routing from '@/mixins/routing'
  import DataTableMixin from '@/mixins/dataTable'
  import User from '@/mixins/user'
  import ApiService from '@/api/api-client'

  export default {
    components: {
      SelectPageLimit,
    },
    mixins: [FormatNumber, Routing, DataTableMixin, User],
    data () {
      return {
        loadingList: false,
        tableKey: 'LoyaltyReportTable',
        sidePanelStatus: {
          active: false,
          mode: 'edit',
          data: null,
        },
        expanded: [],
        tableHeaders: [
          {
            text: 'Операция',
            align: 'start',
            value: 'title',
          },
          {
            text: 'Дата',
            value: 'created_at',
          },
          {
            text: 'Клиент',
            value: 'client',
          },
          {
            text: 'Валюта',
            value: 'unit',
          },
          {
            text: 'Сумма',
            value: 'value',
          },
          {
            text: 'Оператор',
            value: 'operator',
          },
        // { text: '', value: 'data-table-expand' },
        ],
        wordPages: ['странице', 'страницах', 'страницах'],
        wordOperations: ['операция', 'операции', 'операций'],
      }
    },
    computed: {
      tableData () {
        return this.$store.getters['widget/table/widgetData']
      },
      totalCount () {
        return this.$store.getters['widget/table/count']
      },
      pagesCount () {
        const count = Math.ceil(this.totalCount / this.tableSettings.itemsPerPage)
        if (count) {
          if (this.tableSettings.page > count) {
            this.setTableSettingsPage(count)
          }
          return count
        }
        this.setTableSettingsPage(1)
        return 1
      },
      program () {
        return this.$store.getters['company/program/program']
      },
      period () {
        return this.$store.getters['reference/date_selection/period']
      },
      filter () {
        return this.$store.getters['widget/filter/filter']
      },
      bonusResources () {
        return this.$store.getters['company/bonus_resources/bonusResources']
      },
      accountClient () {
        return this.$store.getters['crm/clientCard/client']
      },
      bonusUnits () {
        return this.$store.getters['company/bonus_units/bonusUnits']
      },
    },
    watch: {
      'program.id' (v) {
        if (v) {
          this.fetchData()
        }
      },
      filter (v) {
        if (v) this.fetchData()
      },
      period (v) {
        if (v) this.fetchData()
      },
      'tableSettings.page' (v) {
        if (v) this.fetchData()
      },
      'tableSettings.itemsPerPage' (v) {
        if (v) this.fetchData()
      },
      tableSettings: {
        handler (v) {
          this.setDataTableSetting(this.tableKey, v)
        },
        deep: true,
      },
      accountClient (value) {
        value && value.id && this.fetchData()
      },
    },
    async mounted(){
      await this.getAllBonusUnits()
    },
    created () {
      // переопределяем настройки таблицы по-умолчанию
      this.tableSettings.multiSort = true
      this.tableSettings = this.getDataTableSetting(this.tableKey, this.tableSettings)
      this.fetchData()
    },
    methods: {
      setTableSettingsPage (val) {
        this.tableSettings.page = val
      },
      async userSidePanel (item) {
        console.log('user.....')
        console.log(item)
        console.log('user.....')

        if (item.client_id) {
          const result = await ApiService.get('/api-cabinet/widget/client', {
            params: {
              client_id: item.client_id,
              program_id: this.program.id,
            },
          })
          console.log(result)

          if (result.client && result.client.accounts) {
            this.sidePanelStatus.mode = 'edit'
            this.sidePanelStatus.data = Object.assign({
              program_id: this.program.id,
            }, result.client, result.client.accounts.filter(item=>!item.deleted_at)[0])
            this.sidePanelStatus.active = true
          }
        }
      },
      getDate (date) {
        if (date) return this.$moment.utc(date).local().format(this.$config.date.DATE_FORMAT)
        return '-'
      },
      getTime (date) {
        if (date) {
          const d = this.$moment.utc(date).local().format('HH:mm')
          return 'в ' + d
        }
        return '-'
      },
      getLastActivity (date) {
        if (date) return 'Был(а) в сети ' + this.$moment.utc(date).local().format(this.$config.date.DATETIME_FORMAT_MIN2)
        return 'Был(а) в сети - '
      },
      getValue (value) {
        value = Number(value)
        if (value >= 0) return `<span style="color: #00D15D;">+${value.toLocaleString()}</span>`
        return `<span style="color: #EA4C2A;">${value.toLocaleString()}</span>`
      },
      getWord (number, words) {
        const cases = [2, 0, 1, 1, 1, 2]
        return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
      },
      fetchData () {
        this.loadingList = true
        const list = {
          program_id: this.program.id,
          start_period: this.period.start,
          end_period: this.period.end,
          filter: this.filter,
          offset: this.getOffset(this.tableSettings.page, this.tableSettings.itemsPerPage),
          limit: this.tableSettings.itemsPerPage,
          sortable: this.getSortable(this.tableSettings.sortBy, this.tableSettings.sortDesc),
        }
        // console.log('table/list')
        // console.log(list)
        try {
          this.$store.dispatch('widget/table/widget', list)
        } finally {
          this.loadingList = false
        }
      },
      async getAllBonusUnits(){
        try{
          await this.$store.dispatch('company/bonus_units/loadBonusUnits', this.accountClient.program_id);
        }
        catch{
          console.log("Не удалось загрузить")
        }
      },
      isUnitMain(id){
        const unit = this.bonusUnits.find(item => item.id === id)
        if (unit)
          return unit.is_main
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "~@/styles/vuetify-preset-plus/light_theme/crm/_crm.scss";

.cell-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #2A2A34;
}

.cell-text-bold {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #2A2A34;
}

.cell-hint {
  margin-top: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #9191A1;
}

.table-cell_avatar {
  margin-right: 8px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
}

.table-pagination-block {
  display: flex;
  align-items: center;

  .table-pagination-block-select {
    position: relative;
    top: 6px;
    left: 20px;
    width: 250px;
  }
}
</style>
