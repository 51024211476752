var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticStyle:{"width":"1024px"},attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"plus-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"options":_vm.tableSettings,"single-expand":true,"expanded":_vm.expanded,"item-key":"uuid","show-expand":true,"hide-default-footer":"","server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.tableSettings=$event},"update:expanded":function($event){_vm.expanded=$event},"update:sort-by":function($event){return _vm.fetchData()},"update:sort-desc":function($event){return _vm.fetchData()}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Комментарий: "+_vm._s(item.comment||'-')+" ")])]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-icon',{staticStyle:{"width":"16px"},attrs:{"color":"neutral-500"},on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" $iconify_ion-chatbox-outline ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-text"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"cell-hint"},[_vm._v(" ID "+_vm._s(item.bsid)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-text"},[_vm._v(" "+_vm._s(_vm.getDate(item.created_at))+" ")]),_c('div',{staticClass:"cell-hint"},[_vm._v(" "+_vm._s(_vm.getTime(item.created_at))+" ")])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('img',{staticClass:"table-cell_avatar",staticStyle:{"position":"relative","top":"-5px","cursor":"pointer"},attrs:{"src":("" + (_vm.$config.app.fileStorage) + (item.client_avatar))},on:{"click":function($event){$event.stopPropagation();return _vm.userSidePanel(item)}}}),_c('div',[_c('div',{staticClass:"cell-text-bold",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.userSidePanel(item)}}},[_vm._v(" "+_vm._s(item.client_name || _vm.$t('no_name'))+" ")]),_c('div',{staticClass:"cell-hint"},[_vm._v(" "+_vm._s(_vm.getLastActivity(item.last_activity))+" ")])])])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-text"},[(_vm.isUnitMain(item.unit_id))?_c('v-icon',[_vm._v("mdi-star")]):_vm._e(),_vm._v(" "+_vm._s(item.unit)+" ")],1)]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-text-bold",staticStyle:{"text-align":"right"},domProps:{"innerHTML":_vm._s(_vm.getValue(item.value))}})]}},{key:"item.operator",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticClass:"table-cell_avatar",style:(("background-color: " + (_vm.program.bgcolor1) + ";")),attrs:{"src":(item.operator_avatar === 'system' || item.operator_avatar === 'external')
                ? _vm.program.logo
                : ("" + (_vm.$config.app.fileStorage) + (item.operator_avatar))}}),_c('div',{staticClass:"cell-text"},[_vm._v(" "+_vm._s(item.operator)+" ")])])]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pagination",attrs:{"align":"center"}},[_c('v-col',[_c('div',{staticClass:"table-pagination-block"},[_c('div',{staticStyle:{"margin-right":"20px"}},[_vm._v(" Всего "+_vm._s(_vm.totalCount)+" "+_vm._s(_vm.getWord(_vm.totalCount, _vm.wordOperations))+" на "+_vm._s(_vm.pagesCount)+" "+_vm._s(_vm.getWord(_vm.pagesCount, _vm.wordPages))+" ")]),_c('select-page-limit',{attrs:{"min-width":"200px","items":_vm.paginationOptions,"model":_vm.tableSettings.itemsPerPage,"item-value":"value","item-label":"text"},on:{"update:model":function($event){return _vm.$set(_vm.tableSettings, "itemsPerPage", $event)}}}),_c('div',{staticClass:"app__spacer"}),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"next-icon":"fas fa-chevron-right","prev-icon":"fas fa-chevron-left","length":_vm.pagesCount,"total-visible":7,"circle":""},model:{value:(_vm.tableSettings.page),callback:function ($$v) {_vm.$set(_vm.tableSettings, "page", $$v)},expression:"tableSettings.page"}})],1)],1)])],1),_c('side-panel-edit-client',{attrs:{"mode":_vm.sidePanelStatus.mode,"data":_vm.sidePanelStatus.data},on:{"update:mode":function($event){return _vm.$set(_vm.sidePanelStatus, "mode", $event)}},model:{value:(_vm.sidePanelStatus.active),callback:function ($$v) {_vm.$set(_vm.sidePanelStatus, "active", $$v)},expression:"sidePanelStatus.active"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }